import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkLogin } from "../store/reducers/userReducer";
import RoutesPages from "./routes";

export default function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLogin());
  });

  return <RoutesPages />;
}

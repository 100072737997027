import React from "react";
import {
  Container,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ErrorIcon from "@mui/icons-material/Error";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import LanguageIcon from "@mui/icons-material/Language";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import UpdateIcon from "@mui/icons-material/Update";
import EmailIcon from "@mui/icons-material/Email";
import Header from "../pages-sections/home/Header";
import Footer from "../pages-sections/home/Footer";

function TermsAndConditions() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ mt: 10 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Conditions d'Utilisation
          </Typography>
          <Divider sx={{ mb: 2 }} />

          <Typography variant="body1" paragraph>
            Bienvenue sur SIC ! En utilisant notre service, vous
            acceptez de vous conformer aux conditions d'utilisation suivantes.
            Veuillez les lire attentivement.
          </Typography>

          <List>
            <ListItem>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText
                primary="1. Acceptation des Conditions"
                secondary="En utilisant SIC, vous acceptez nos termes et conditions. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText
                primary="2. Services Offerts"
                secondary="Nous fournissons des services de transfert de fonds, achat de cartes cadeaux, top-ups, et plus. Les détails et la disponibilité des services peuvent changer."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText
                primary="3. Frais et Tarifs"
                secondary="Les frais de service sont clairement affichés sur notre site. Vous acceptez de payer ces frais lors de l'utilisation de nos services."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <ErrorIcon />
              </ListItemIcon>
              <ListItemText
                primary="4. Responsabilités de l'Utilisateur"
                secondary="Vous êtes responsable de l'exactitude des informations fournies et de la conformité avec les lois locales lors de l'utilisation de nos services."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <PrivacyTipIcon />
              </ListItemIcon>
              <ListItemText
                primary="5. Confidentialité"
                secondary="Nous nous engageons à protéger vos données personnelles. Veuillez consulter notre politique de confidentialité pour plus d'informations."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                primary="6. Modifications des Conditions"
                secondary="Nous pouvons modifier ces conditions à tout moment. Les changements seront publiés sur notre site et seront effectifs dès leur publication."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText
                primary="7. Support Client"
                secondary="Pour toute question ou problème, veuillez contacter notre service client. Nous sommes là pour vous aider."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <UpdateIcon />
              </ListItemIcon>
              <ListItemText
                primary="8. Modification des Services"
                secondary="Nous nous réservons le droit de modifier ou d'interrompre nos services à tout moment. Nous vous informerons de ces changements par le biais de notifications sur notre site."
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText
                primary="9. Contact"
                secondary={
                  <>
                    Si vous avez des questions concernant nos Conditions
                    d'Utilisation, veuillez nous contacter :
                    <br />
                    - Email : support@lajan.ht
                    <br />- Adresse : 123 Rue de la Paix, Port-au-Prince, Haïti
                  </>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default TermsAndConditions;
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function DialogComponent({
  open,
  handleClose,
  Content,
  message,
}) {
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {" "}
        {message
          ? message
          : "Select acount and type of transaction Debit or Credit an the amount"}
      </DialogTitle>
      <DialogContent>{Content}</DialogContent>
      <DialogActions>
        <Button
          sx={{ marginRight: "32px" }}
          variant="contained"
          color="error"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

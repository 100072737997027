import { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import { postData } from "../../fetch/post";
import useSessionStorage from "../../hooks/useSessionStorage";

export const AccountPassword = () => {
  const [values, setValues] = useState({
    password: "",
    confirm: "",
  });

  // const [error, loading, data] = useGet(`${process.env.REACT_APP_USER}`)

  const [usd, setUsd] = useState("");
  const [htg, setHtg] = useState("");
  const [select, setSelect] = useState("");

  const [message, setMessage] = useState("");

  const user = useSessionStorage("user");

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleMessage = async () => {
    if (message) {
      const res = await postData(`${process.env.REACT_APP_CONTACT}/`, {
        email: user.email,
        message,
        number: "+5093444444",
      });
      setMessage("");
      return;
    }
    toast("Un message est requis !!!");
    return;
  };

  const handleSentCurrency = async () => {
    if (usd || htg) {
      const res = await postData(
        `${process.env.REACT_APP_USER}/change_currency/`,
        {
          usd,
          htg,
        }
      );
      if (res.message === "success") {
        setHtg("");
        setUsd("");
        toast("Le taux a été mise à jour avec succès !");
        return;
      } else {
        toast(res.message);
        return;
      }
    } else {
      if (usd === 0) {
        toast("Le taux  doit supérieur à zéro !");
      }
      if (htg === 0) {
        toast("Le taux  doit supérieur à zéro !");
      }
      toast("Les Taux sont requis !");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/** 
      <Card>
        <CardHeader subheader="Mettre à jour le mot de passe" title="Mot de passe" />
        <Divider />
        <CardContent>
          <Stack spacing={3} sx={{ maxWidth: 400 }}>
            <TextField
              fullWidth
              label="Mot de passe"
              name="password"
              onChange={handleChange}
              type="password"
              value={values.password}
            />
            <TextField
              fullWidth
              label="Mot de passe (Confirmer)"
              name="confirm"
              onChange={handleChange}
              type="password"
              value={values.confirm}
            />
          </Stack>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained">Mettre à jour</Button>
        </CardActions>
      </Card>
      */}
      {
        /**user?.is_trader **/ true ? (
          <Card sx={{ mt: 0 }}>
            <CardHeader
              subheader="Gérer les taux de change"
              title="Taux de change"
            />
            <Divider />
            <CardContent>
              {user.currencys?.map((value, key) =>
                value.code === "USD" ? (
                  <Typography key={key} variant="body1" color="initial">
                    À la vente -&gt; {value.taux}
                  </Typography>
                ) : (
                  <Typography key={key} variant="body1" color="initial">
                    À l'Achat -&gt; {value.taux}
                  </Typography>
                )
              )}

              <Stack spacing={3} mt={3} sx={{ maxWidth: 400 }}>
                <TextField
                  select
                  label="mettre à jour le taux du jour"
                  value={select}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  <MenuItem value="">....</MenuItem>
                  <MenuItem value="À l'achat">À l'achat</MenuItem>
                  <MenuItem value="À la vente">À la vente</MenuItem>
                </TextField>
                {select === "À l'achat" && (
                  <TextField
                    fullWidth
                    type="number"
                    label="À l'achat: Taux lorsque vous achetez des USD"
                    name="htg"
                    onChange={(e) =>
                      setHtg(parseFloat(e.target.value.replace(/-/g, "")))
                    }
                    value={htg}
                    inputProps={{ min: 1 }}
                  />
                )}
                {select === "À la vente" && (
                  <TextField
                    fullWidth
                    type="number"
                    inputProps={{ min: 1 }}
                    label="À la vente: Taux lorsque vous vendez des USD"
                    name="usd"
                    onChange={(e) =>
                      setUsd(parseFloat(e.target.value.replace(/-/g, "")))
                    }
                    value={usd}
                  />
                )}
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button onClick={handleSentCurrency} variant="contained">
                Mettre à jour
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Card sx={{ mt: 4 }}>
            {/** ask for traders zone */}
            <CardHeader
              subheader="Envoyez-nous un message"
              title="Demandez à être Trader"
            />
            <Divider />
            <CardContent>
              <Stack spacing={3} sx={{ maxWidth: 400 }}>
                <TextField
                  fullWidth
                  multiline
                  label="Je suis ....."
                  name="htg"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  rows={6}
                />
              </Stack>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button onClick={handleMessage} variant="contained">
                Envoyer
              </Button>
            </CardActions>
          </Card>
        )
      }
    </form>
  );
};

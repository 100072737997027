import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { SvgIcon } from "@mui/material";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  //SwapHorizOutlined,
} from "@mui/icons-material";

export const items = [
  {
    title: "Aperçu",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Ajouter de l'argent",
    path: "/transaction/add",
    icon: (
      <SvgIcon fontSize="small">
        <Add />
      </SvgIcon>
    ),
  },

  {
    title: "Convertir",
    path: "/transaction/send",
    icon: (
      <SvgIcon fontSize="small">
        <ArrowUpward />
      </SvgIcon>
    ),
  },

  {
    title: "Transfert",
    path: "/transaction/convert",
    icon: (
      <SvgIcon fontSize="small">
        <SyncAltIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Retirer",
    path: "/transaction/withdraw",
    icon: (
      <SvgIcon fontSize="small">
        <ArrowDownward />
      </SvgIcon>
    ),
  },
  /** 
  {
    title: "Clients",
    path: "/customers",
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  
  {
    title: "Courtiers en devises",
    path: "/traders",
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingBagIcon />
      </SvgIcon>
    ),
  },
  */
  {
    title: "Compte",
    path: "/account",
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Gérer les retraits",
    path: "/settings",
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    ),
  },
];

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { postData, postDataWithAuth } from "../fetch/post";
import { toast } from "react-toastify";

const RechargeForm = () => {
  const [telephone, setTelephone] = useState("");
  const [status, setStatus] = useState("en attente"); // Statut du paiement
  const [loading, setLoading] = useState(false);
  const [intervalID, setIntervalID] = useState(null);
  const [data, setData] = useState(null); // Pour stocker l'interval ID

  const transactionId = new URLSearchParams(location.search).get(
    "transactionId"
  );

  const token = new URLSearchParams(location.search).get("token");

  const amount = new URLSearchParams(location.search).get("amount");

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (telephone.length !== 8) {
      alert("Veuillez entrer un numéro de téléphone valide de 8 chiffres.");
      return;
    }

    setLoading(true); // Activer le chargement
    try {
      // Envoi de la requête à l'API
      const result = await postDataWithAuth(
        `${process.env.REACT_APP_ACCOUNT}/recharge/`,
        {
          telephone: telephone,
        },
        token
      );

      if (result.key) {
        setData(result);
        setStatus("en attente");
        // Démarrer la vérification du statut une fois que la requête a réussi
        const interval = setInterval(() => startCheckingStatus(), 5000);
        setIntervalID(interval); // Stocker l'interval ID pour arrêter plus tard
      } else {
        setStatus("échec");
      }
    } catch (error) {
      console.error("Erreur lors de l'appel API :", error);
      setStatus("échec");
    }
    // setLoading(false);
  };

  // Fonction pour vérifier le statut du paiement
  const startCheckingStatus = async () => {
    try {
      const res = await postDataWithAuth(
        `${process.env.REACT_APP_ACCOUNT}/check_payment_status/`,
        {
          key: data.key,
          cli: telephone,
        },
        token
      );
      if (res.message) {
        setStatus("payer");
        setLoading(false);
        window.location.href = `/payment/auth/verify?transactionId=${transactionId}&token=${token}`;
        clearInterval(intervalID); // Arrêter l'intervalle une fois le paiement validé
      }
    } catch (error) {
      console.log("Erreur lors du chargement: ", error);
    }
  };

  // Nettoyer l'interval lorsqu'on quitte ou rafraîchit la page
  useEffect(() => {
    if (intervalID) {
      const interval = setInterval(startCheckingStatus, 5000);
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [telephone, intervalID]); // Rerun this effect when `telephone` or `intervalID` changes

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh", padding: 3 }}
    >
      <Typography variant="h4" gutterBottom>
        Process payment Moncash or Natcash
      </Typography>
      <Typography variant="h6" gutterBottom>
        {data?.message}
      </Typography>
      <Typography variant="body1" align="center" marginBottom={2}>
        Montant à payer : HTG {amount}
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: 400 }}>
        <TextField
          fullWidth
          label="Numéro de téléphone"
          variant="outlined"
          type="number"
          required
          name="phone"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
          placeholder="Entrer votre numéro de téléphone"
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ marginTop: 2 }}
        >
          Démarrer Recharge
        </Button>
      </form>

      {loading ? (
        <Box mt={2}>
          <CircularProgress />
          <Typography id="status" mt={2} variant="body1">
            Please waiting....
          </Typography>
        </Box>
      ) : (
        <Typography id="status" mt={2} variant="body1">
          {status}
        </Typography>
      )}
    </Box>
  );
};

export default RechargeForm;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Layout } from "../../layouts/auth/layout";
import Verify from "../../pages-sections/account/user/verify";

import { postData } from "../../fetch/post";
import { userVerify } from "../../store/reducers/userReducer";
import { toast } from "react-toastify";

export default function VerifyPage() {
  const [otp, setOTP] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  // Verify account function
  const handleVerifyAccount = async () => {
    await dispatch(userVerify({ otp }));
  };

  // Function to handle sending OTP to API
  const sendOTPToAPI = async () => {
    setOpen(true);
    const res = await postData(`${process.env.REACT_APP_USER}/verify_otp/`, {
      pin: otp,
    });

    if (res.message === "success") {
      setOpen(false);
      handleVerifyAccount();
    } else {
      toast("L'OTP a expiré");
      setOpen(false);
    }
  };

  console.log(user.user);

  useEffect(() => {
    if (user.is_email_check) navigate("/dashboard");
  }, [user, navigate]);

  return (
    <Layout>
      <Verify setOTPPass={setOTP} open={open} sendOTPToAPI={sendOTPToAPI} />
    </Layout>
  );
}

import React from "react";
import { Layout } from "../../layouts/dashboard/layout";
import Index from "../../pages-sections/wallet";

export default function WalletPage() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}

import React from "react";
import { Layout } from "../layouts/dashboard/layout";
import Index from "../pages-sections/companies";

export default function Companies() {
  return (
    <Layout>
      <Index />
    </Layout>
  );
}
import * as React from "react";
import { format } from "date-fns";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";

export default function VerticalLinearStepper({ status, done }) {
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (done) setActiveStep(status?.length);
    else setActiveStep(status?.length - 1);
  }, [status]);

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {status?.map((step, index) => (
        <Step key={step.id}>
          <StepLabel
            onClick={() =>
              setActiveStep(index === status?.length ? status?.length : index)
            }
            sx={{ cursor: "pointer" }}
            optional={
              index === status.length ? (
                <Typography variant="caption">Last step</Typography>
              ) : null
            }
          >
            <Typography fontSize={16} fontWeight={600}>
              {step.description}
            </Typography>{" "}
            <Typography fontSize={10} color="grey.600">
              {format(step.update_at, "dd/MM/yyyy HH:mm:ss")}
            </Typography>
          </StepLabel>
          <StepContent>
            <Typography>{step.name}</Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function ALert({ open, message, color, setOpen }) {
    return(
        <Box sx={{ width:{ xs : "100%", md: "100%"}, marginTop: "25px" }}>
            <Collapse in={open}>
            <Alert
                color={color}
                action={
                  <IconButton
                    aria-label="close"
                    color={color}
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
            >
            {message}
            </Alert>
            </Collapse>
        </Box>
    )
}
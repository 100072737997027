import { useState, useEffect } from "react";
import {
  Box,
  Container,
  Pagination,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { CompanyCard } from "./company-card";
import { CompaniesSearch } from "./companies-search";
import { useGet } from "../../hooks/useGet";
import { getData } from "../../fetch/get";

import MapSection from "./mapSection";
import { toast } from "react-toastify";
import { postData } from "../../fetch/post";

const Page = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const [users, loading, error] = useGet(`${process.env.REACT_APP_USER}/`);

  const handlePagination = async (event, page) => {
    const res = await getData(`${process.env.REACT_APP_USER}?page=${page}`);
    if (res.message === "success") {
      setData(res);
      return;
    }
    toast("error to get data");
  };

  useEffect(() => {
    setData(users);
  }, [users]);

  if (loading) {
    return <h4>Loading...</h4>;
  }

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      // Add the logic you want to execute on Enter key press
      if (search) {
        const res = await postData(`${process.env.REACT_APP_USER}/search/`, {
          search,
        });
        if (res) {
          setData(res);
          return;
        }
      }
    }
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Currency Traders</Typography>
              </Stack>
            </Stack>
            <CompaniesSearch
              value={search}
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
            />
            <Grid container spacing={3}>
              {data?.data?.map((company) => (
                <Grid xs={12} md={6} lg={4} key={company.id}>
                  <CompanyCard company={company ? company : []} />
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                onChange={(event, page) => handlePagination(event, page)}
                count={data?.count}
                page={Number(data?.page)}
                size="small"
              />
            </Box>
          </Stack>
          {/**   <MapSection /> */}
        </Container>
      </Box>
    </>
  );
};

export default Page;

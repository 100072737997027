import React from "react";
import { Box, Container, Grid } from "@mui/material";
import HorizontalScrollCardGrid from "../../components/horizontalScrollCardGrid";

export default function Index() {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} lg={12}>
              <HorizontalScrollCardGrid />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Complete() {
  return (
    <Grid container spacing={0}>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <img
          width={116}
          height={116}
          alt="complete"
          src="https://funtura.in/tvm/wp-content/themes/funtura/assets/images/success.svg"
        />
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        mt={5}
      >
        <Typography variant="h5" component="h3" lineHeight={1.1} mt="1.5rem">
          Your transaction is completed!
        </Typography>
      </Grid>
      <Grid m={0} item md={12}>
        <Typography
          variant="body1"
          style={{ textAlign: "center" }}
          color="textSecondary"
          mt="0.3rem"
        >
          You will be receiving a confirmation email with transaction details.
        </Typography>
      </Grid>
    </Grid>
  );
}

import React from "react";
import {
  CircularProgress,
  Typography,
  Stack,
  LinearProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import Verify from "../../pages-sections/payment/verify";
import { postDataWithAuth } from "../../fetch/post";

export default function PaymentVerify() {
  const [otp, setOTP] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState(null); // State to store the error message

  const token = new URLSearchParams(location.search).get("token");
  const transactionId = new URLSearchParams(location.search).get(
    "transactionId"
  );

  React.useEffect(() => {
    if (!token) {
      setError("Token is missing. Please ensure you have a valid token.");
    } else if (!transactionId) {
      setError(
        "Transaction ID is missing. Please ensure you have a valid transaction ID."
      );
    } else {
      setError(null); // Clear error if both are present
      sendOTPToAPI();
    }
  }, [token, transactionId]);

  const handleSentData = (data) => {
    const authResponse = {
      return_url: data.return_url, // Replace with the actual return URL or transaction ID.
      // Optional: include a token if needed.
    };

    // Ensure window.opener exists before sending a message.
    if (window.opener) {
      window.opener.postMessage(authResponse, "https://clearsig.ht"); // Replace with the main app's domain.
      window.close(); // Optionally close the popup after sending the message.
    } else {
      console.warn("No opener found. Cannot send data to the parent.");
      // Redirect directly if no opener is available.
      window.location.href = data.return_url;
    }
  };

  const sendOTPToAPI = async () => {
    setSent(true);

    try {
      console.log("otp :", otp);
      const res = await postDataWithAuth(
        `${process.env.REACT_APP_PAYMENT}/transaction_complete/`,
        {
          pin: otp,
          transactionId,
        },
        token
      );

      if (res.message === "success") {
        setSent(false);
        handleSentData(res);
        return;
      } else {
        toast(res.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast("An error occurred while completing the transaction.");
    } finally {
      setSent(false);
    }
  };

  if (sent) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <h1> Payment verification ...</h1>
          <LinearProgress color="secondary" sx={{ width: "50%" }} />
          <LinearProgress color="success" sx={{ width: "50%" }} />
          <LinearProgress color="inherit" sx={{ width: "50%" }} />
          <h3>You will be redirected ...</h3>
        </Stack>
      )}
    </div>
  );
}

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        backgroundImage: 'url(https://images.unsplash.com/photo-1644871913429-9ed7696180cc?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        textAlign: 'center',
        padding: 4,
        position: 'relative',
        zIndex: 1,
      }}
    >
      {/* Overlay semi-transparent pour améliorer la lisibilité du texte */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: -1,
        }}
      />
      <Typography variant="h2" gutterBottom>
        Bienvenue sur le Service d'Intermédiation de Change (SIC)
      </Typography>
      <Typography variant="h5" gutterBottom>
        Facilitez vos échanges de devises avec notre plateforme en ligne.
      </Typography>
      <Button LinkComponent={Link} to="/register" variant="contained" color="primary" size="large">
        Commencer Maintenant
      </Button>
    </Box>
  );
};

export default HeroSection;

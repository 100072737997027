import React from "react";
import { Layout } from "../layouts/dashboard/layout";
import { SettingsNotifications } from "../pages-sections/settings/settings-notifications";

export default function SettingsPage() {
  return (
    <Layout>
      <SettingsNotifications />
    </Layout>
  );
}

import React from "react";
import { Box, Typography, TextField, Button, Container } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

// Validation schema with Yup
const contactFormSchema = Yup.object({
  name: Yup.string().required("Required"),
  number: Yup.number("Must be digit").required("Required"),
  email: Yup.string().email("Please enter a valid email").required("Required"),
  message: Yup.string().min(10, "10 characters Min").required("Required"),
});

const Contact = () => {
  // Formik hook
  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
      email: '',
      message: '',
    },
    validationSchema: contactFormSchema,
    onSubmit: (values) => {
      console.log('Form values:', values);
      // Handle form submission (e.g., send data to server)
    },
  });

  return (
    <Box sx={{ padding: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Contactez-nous
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            margin: "0 auto",
          }}
        >
          <TextField
            label="Nom"
            variant="outlined"
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            label="Numéro de Téléphone"
            variant="outlined"
            fullWidth
            name="number"
            type="number"
            value={formik.values.number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
          <Button variant="contained" color="primary" type="submit">
            Envoyer
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;

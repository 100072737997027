import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <AppBar position="static">
      <Container maxWidth="lg">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            SIC - Service d'Intermédiation de Change
          </Typography>
          <Button 
            LinkComponent={Link}
            to='/login'
            color="primary" 
            variant='contained'
            sx={{ 
              backgroundColor: '#4338ca', // Bleu pour le bouton Connexion
              marginRight: 1, 
              '&:hover': {
                backgroundColor: '#4338ca', // Couleur au survol
              },
            }}
          >
            Connexion
          </Button>
          <Button 
            color="inherit" 
            LinkComponent={Link}
            to='/register'
          >
            Inscription
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

import * as Yup from "yup";

/**
 * The password must match:
 *
 * At least 8 - 16 characters,
 * must contain at least 1 uppercase letter,
 * must contain at least 1 lowercase letter,
 * and 1 number
 * Can contain any of these special characters: $ % # * & - .
 */
const passwordRules = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/;

const registerSchema = Yup.object({
    last_name: Yup.string().required("Requis"),
    first_name: Yup.string().required("Requis"),
    email: Yup.string().email("Veuillez entrer un email valide").required("Requis"),
    password: Yup.string().matches(passwordRules, { message: "Veuillez créer un mot de passe plus fort !" }).required("Requis"),
    re_password: Yup.string().oneOf([Yup.ref("password"), null], "Les mots de passe doivent correspondre").required("Requis"),
});

export default registerSchema;

import React from "react";
import Header from "../pages-sections/home/Header";
import HeroSection from "../pages-sections/home/HeroSection";
import Features from "../pages-sections/home/Features";
import HowItWorks from "../pages-sections/home/HowItWorks";
import Testimonials from "../pages-sections/home/Testimonials";
import VideoTuto from "../pages-sections/home/VideoTuto";
import Faq from "../pages-sections/home/Faq";
import Contact from "../pages-sections/home/Contact";
import Footer from "../pages-sections/home/Footer";

const App = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <Features />
      <HowItWorks />
      <Testimonials />
      <VideoTuto />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;

export function hideLastCharacters(str, show=false){
  if (show) {
    // If show is true, return the original string
    return str;
  } 

  if (typeof str !== 'string') {
      return "error display/hide";
  }
  if (str.length < 4) {
      return "String length is less than 4.";
  }
  const hiddenPart = "*".repeat(str.length - 4);
  const visiblePart = str.slice(-4);
  return hiddenPart + visiblePart;
}

export function toggleCharactersVisibility(str, show=false) {
  if (show) {
    // If show is true, return the original string
    return str;
  } else {
    // If show is false, return a string of asterisks (*) with the same length as the input string
    return "*".repeat(str.length);
  }
}

import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { Layout } from "../../layouts/dashboard/layout";
import Sent from "../../pages-sections/transaction/sent/index";
import Verify from "../../pages-sections/transaction/sent/verify";
import Complete from "../../pages-sections/transaction/convert/complete";

import Stepper from "../../components/stepper";

import { useGet } from "../../hooks/useGet";
import { postData } from "../../fetch/post";

const steps = ["Choisir un compte", "Vérifier les informations", "Compléter"];

export default function ConvertPage() {
  const [account, setAccount] = React.useState(null);
  const [taux, setTaux] = React.useState("");
  const [accountReceive, setAccountReceive] = useState(null);
  const [amount, setAmount] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [otp, setOTP] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get/`
  );

  const sendOTPToAPI = async () => {
    setSent(true);
    const res = await postData(`${process.env.REACT_APP_USER}/verify_otp/`, {
      pin: otp,
    });
    if (res.message === "success") {
      setOpen(!open);
      setSent(false);
      handleSentData();
      return;
    } else {
      toast(res.message);
      setSent(false);
    }
    setSent(false);
  };

  if (loading) {
    return <h1>Chargement...</h1>;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const allStepsCompleted = () => {
    return Object.keys(completed).length === steps.length;
  };

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = async () => {
    if (taux < 100 || !taux) {
      toast("Le taux doit être supérieur ou égal à 100");
      return;
    }

    if (!account) {
      toast("Sélectionner un compte");
      return;
    }

    if (!amount) {
      toast("Un montant requis");
      return;
    }

    if (account?.currency.code === "USD" && amount < 5) {
      toast("Le montant doit être supérieur ou égal à 5");
      return;
    }
    if (account?.currency.code === "HTG" && amount < 150) {
      toast("Le montant doit être supérieur ou égal à 150");
      return;
    }

    if (!accountReceive) {
      toast("Sélectionner un compte de réception");
      return;
    }

    if (account?.currency.code === accountReceive?.currency.code) {
      toast("Choisir une devise différente");
      return;
    }

    if (Number(account.amount) < Number(amount)) {
      toast("Fonds insuffisants !");
      return;
    }

    await handleCheckAvailable();
  };

  const handleCheckAvailable = async () => {
    let Link = "";
    if (accountReceive.currency.code === "USD") {
      Link = `${process.env.REACT_APP_ACCOUNT}/check_buy_rate/`;
    } else {
      Link = `${process.env.REACT_APP_ACCOUNT}/check_buy_htg_haute/`;
    }

    const request = await fetch(Link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      credentials: "include",
      mode: "cors",
      cache: "default",
      body: JSON.stringify({
        account_sender_id: account.id,
        account_receive_id: accountReceive.id,
        taux: taux,
        amount: amount,
        pin: otp,
      }),
    });

    const res = await request.json();

    if (res.message === "success") {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
      return;
    } else if (res.message_available) {
      toast(res.message_available, {
        position: "top-center",
        autoClose: 25000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
      return res.rest;
    } else {
      toast(res.message);
      return;
    }
  };

  const handleSentData = async () => {
    let Link = "";
    if (accountReceive.currency.code === "USD") {
      Link = `${process.env.REACT_APP_ACCOUNT}/buy_rate_usd/`;
    } else {
      Link = `${process.env.REACT_APP_ACCOUNT}/buy_rate_htg_haute/`;
    }
    const res = await postData(Link, {
      account_sender_id: account.id,
      account_receive_id: accountReceive.id,
      taux: taux,
      amount: amount,
      pin: otp,
    });
    if (res.message === "success") {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
      return;
    } else {
      toast(res.message);
      return null;
    }
  };

  return (
    <Layout>
      <Box>
        <Grid
          container
          mt={5}
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Stepper
            steps={steps}
            activeStep={activeStep}
            completed={completed}
          />
        </Grid>

        <h3 style={{ marginLeft: "3%" }}>Convertir de devises</h3>
        {activeStep === 0 && data ? (
          <Sent
            data={data}
            amount={amount}
            accountReceive={accountReceive}
            setAccountReceive={setAccountReceive}
            setAccount={setAccount}
            account={account}
            taux={taux}
            setTaux={setTaux}
            setAmount={setAmount}
            handleComplete={handleComplete}
          />
        ) : null}
        {activeStep === 1 && data ? (
          <Verify
            amount={amount}
            account={account}
            accountReceive={accountReceive}
            handleBack={handleBack}
            setOTP={setOTP}
            sent={sent}
            sendOTPToAPI={sendOTPToAPI}
            open={open}
            taux={taux}
            setOpen={setOpen}
          />
        ) : null}
        {activeStep === 2 && data ? <Complete data={data} /> : null}
      </Box>
    </Layout>
  );
}

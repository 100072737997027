import { useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { OverviewBudget } from "../../pages-sections/overview/overview-budget";
import HorizontalScrollCardGrid from "../../components/horizontalScrollCardGrid";
import { useGet } from "../../hooks/useGet";

export const SettingsNotifications = () => {
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get_withdraw/`
  );

  if (error) {
    return <h3>Erreur de récupération des retraits</h3>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="Gérer les retraits" title="Option de Retrait" />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid xs={12} sm={6} md={12}>
              <HorizontalScrollCardGrid
                data={data}
                loading={loading}
                CardItem={OverviewBudget}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
    </form>
  );
};

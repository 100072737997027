import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#333', color: '#fff', padding: 2, marginTop: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} SIC. Tous droits réservés.
        </Typography>
        <Typography variant="body2" align="center">
          <Link href="/privacy" color="inherit" underline="hover" sx={{ margin: 1 }}>
            Politique de Confidentialité
          </Link> | 
          <Link href="/condition" color="inherit" underline="hover" sx={{ margin: 1 }}>
            Conditions d'Utilisation
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { toast } from "react-toastify";

import DropZone from "./DropZone";
import BazaarImage from "./BazaarImage";
import { FlexBox } from "./flex-box";
import { UploadImageBox, StyledClear } from "./styledComponents";

import { postBinary } from "../fetch/post";

export default function formAddAccount({ id }) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState("");
  const [files, setFiles] = useState([]);

  // HANDLE UPDATE NEW IMAGE VIA DROP ZONE
  const handleChangeDropZone = (files, setFiles) => {
    files.forEach((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles(files);
  };

  // HANDLE DELETE UPLOAD IMAGE
  const handleFileDelete = (file, setFiles) => () => {
    setFiles((files) => files.filter((item) => item.name !== file.name));
  };

  const handleSent = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("number", number);
    formData.append("flag", flag);
    formData.append("id", id);
    formData.append("image", files[0]);

    if (name && number) {
      const res = await postBinary(
        `${process.env.REACT_APP_ACCOUNT}/edit_withdraw/`,
        formData
      );
      if (res.message === "success") {
        toast(res.message);
        window.location.reload();
        return;
      } else {
        toast(res.message);
        return;
      }
    }
    toast("required (name && number && flag)");
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <TextField
          label="Name Account"
          fullWidth
          placeholder="ex : Joe Do"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          label="Number Account"
          fullWidth
          placeholder="ex: 323444333"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setFlag(e.target.value)}
          select
          fullWidth
          label="Bank Account"
          value={flag}
        >
          <MenuItem value="">....</MenuItem>
          <MenuItem value="https://www.sogebank.com/wp-content/uploads/2017/12/sogebank.png">
            SogeBank
          </MenuItem>
          <MenuItem value="https://play-lh.googleusercontent.com/4H2rEreKNnL_axWHZ8VEgIT4VHK0g0fN30Q96V56ESgULp5-wbIbyg_aZhqCwazIaPhV=w240-h480-rw">
            Moncash
          </MenuItem>
          <MenuItem value="https://static.wixstatic.com/media/1f0c3c_c4ca7277a6614994aa449d080f200c1e~mv2.png/v1/fill/w_280,h_262,al_c,lg_1,q_85,enc_auto/unibank-logo.png">
            Unibank
          </MenuItem>
          <MenuItem value="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXB8zbNpLaU2qabX-MQIzHt5RrYzl3I3uDc4BB7Y4gng&s">
            BUH
          </MenuItem>
          <MenuItem value="https://www.capitalbankhaiti.biz/wp-content/uploads/2023/11/Chronologie-1996-CapitalBank-768x512.jpg">
            Capital
          </MenuItem>
          <MenuItem value="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeaBxOb_4lOqbu8Yy9J3tQEc-ECxPAIZ1XX8SYjTRqM81NTVKNCMdKxjXFd-spgP8IjGw&usqp=CAU">
            Bnc
          </MenuItem>
        </TextField>
      </Grid>

      <Grid xs={12} md={12}>
        <span>Photo of a check or bank book</span>
        <DropZone onChange={(files) => handleChangeDropZone(files, setFiles)} />

        <FlexBox flexDirection="row" mt={2} flexWrap="wrap" gap={1}>
          {files.map((file, index) => {
            return (
              <UploadImageBox key={index}>
                <BazaarImage src={file.preview} width="100%" />
                <StyledClear onClick={handleFileDelete(file)} />
              </UploadImageBox>
            );
          })}
        </FlexBox>
      </Grid>

      <Grid item md={12} xs={12}>
        <Button onClick={handleSent} fullWidth variant="outlined">
          Edit
        </Button>
      </Grid>
    </Grid>
  );
}

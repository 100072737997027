import React, { useState, useRef } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { postData } from "../../../fetch/post";
import useSessionStorage from "../../../hooks/useSessionStorage";
import { toast } from "react-toastify";

export default function Verify({ setOTPPass, sendOTPToAPI, open }) {
  // get user
  const user = useSessionStorage("user");
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");

  const firstInputRef = useRef(null); // Ref for the first input field

  // Function to handle input change and manage focus
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    const prevInput = document.getElementById(`otp-input-${index - 1}`);
    const nextInput = document.getElementById(`otp-input-${index + 1}`);

    // Update value in the current TextField
    if (value.length === 1 && nextInput) {
      // If a character is entered and it's not the last TextField, focus on the next TextField
      nextInput.focus();
    } else if (value.length === 0 && prevInput) {
      // If character is deleted and it's not the first TextField, focus on the previous TextField
      prevInput.focus();
    }

    // Update OTP state
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP.join(""));
    setOTPPass(newOTP.join(""));
  };

  const handleGenerateOTP = async () => {
    const ask = window.confirm("You want to generate a new OTP ?");
    if (ask) {
      const res = await postData(`${process.env.REACT_APP_USER}/generate_otp/`);
      if (res !== null && res !== undefined) {
        toast("pin sent to your email");
        return;
      } else {
        toast("erreur to sent OTP");
      }
      setOpen(false);
    }
    return;
  };

  return (
    <>
      <Box
        sx={{
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <Stack spacing={0}>
            <Typography mb={2} variant="h4">
              Vérification en deux étapes 💬
            </Typography>
            <Typography color="text.secondary">
              Nous avons envoyé un code de vérification sur votre mobile. Entrez
              le code de le mobile dans le champ ci-dessous.
            </Typography>
            <Typography mb={5} color="text.primary">
              {user.user?.email}
            </Typography>
          </Stack>

          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
          >
            <div>
              <Typography color="text.secondary">
                Tapez votre code de sécurité à 6 chiffres
              </Typography>
              <Stack direction="row" mt={1} mb={3} sx={{}} spacing={3}>
                {[...Array(6)].map((_, index) => (
                  <Box key={index}>
                    <TextField
                      size="medium"
                      variant="outlined"
                      inputProps={{
                        style: { textAlign: "center" },
                        maxLength: 1,
                        inputMode: "numeric",
                      }}
                      onChange={(e) => handleInputChange(index, e)} // Pass index to handleInputChange function
                      id={`otp-input-${index}`}
                      autoFocus={index === 0} // Autofocus on the first input field
                      ref={index === 0 ? firstInputRef : null} // Set ref for the first input field
                    />
                  </Box>
                ))}
              </Stack>
            </div>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={sendOTPToAPI}
              disabled={otp?.length !== 6}
            >
              Confimer
            </Button>
            <Stack mt={2} spacing={1} direction="row">
              <Typography color="text.secondary">
                Vous n'avez pas reçu le code ?
              </Typography>
              <Typography
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={(e) => handleGenerateOTP()}
              >
                Renvoyer
              </Typography>
            </Stack>
          </form>
        </Box>
      </Box>
    </>
  );
}

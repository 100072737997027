import React, { useState, useRef, useEffect } from "react";
import { IconButton, Box, Card, Button } from "@mui/material";
import { Add, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import DialogComponent from "./dialog";
import AddFormAccount from "./addFormAccount";

import { currency } from "../utils/lib";

const HorizontalScrollCardGrid = ({ CardItem, data, loading }) => {
  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      if (container) {
        setShowLeftButton(container.scrollLeft > 0);
        setShowRightButton(
          container.scrollLeft < container.scrollWidth - container.clientWidth
        );
      }
    };

    if (container) {
      handleScroll(); // Call the handler once to determine initial button visibility
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const scrollRightHandle = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left:
          containerRef.current.scrollLeft + containerRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollLeftHandle = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left:
          containerRef.current.scrollLeft - containerRef.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "& .simplebar-content": {
          height: "100%",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400",
        },
      }}
    >
      <DialogComponent
        open={open}
        handleClose={handleClose}
        message={"add account"}
        Content={<AddFormAccount />}
      />
      <Box
        ref={containerRef}
        id="card-container"
        sx={{
          display: "flex",
          overflowX: "auto",
          scrollBehavior: "smooth",
          "-webkit-overflow-scrolling": "touch", // for smooth scrolling on iOS
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "neutral.400",
          },
        }}
      >
        {data ? (
          data.map((card) => (
            <CardItem
              key={card.id}
              id={card.id}
              title={card.bank_name}
              currency={card.currency?.code}
              flag={card.flag ? card.flag : card.currency?.flag}
              number={card.number}
              is_active={card.is_active}
              difference={12}
              positive
              sx={{
                height: "100%",
                m: 1,
                minWidth: { md: "40%", xs: "70%", lg: "25%" },
                cursor: "pointer",
              }}
              value={currency(card.amount)}
            />
          ))
        ) : (
          <h1>Loading ...</h1>
        )}

        <Card
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "25%", // Adjust padding as needed
            height: "198px",
            m: 1,
            minWidth: { md: "40%", xs: "70%", lg: "25%" },
            "& .simplebar-content": {
              height: "100%",
            },
            "& .simplebar-scrollbar:before": {
              background: "neutral.400",
            },
          }}
        >
          <Button onClick={handleClose} startIcon={<Add />}>
            Add an Account
          </Button>
        </Card>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "neutral.400",
          },
        }}
      >
        <IconButton
          disabled={!showLeftButton}
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={scrollLeftHandle}
          aria-label="scroll previous"
        >
          <ArrowBackIos sx={{ color: "#000", paddingLeft: "3px" }} />
        </IconButton>

        <IconButton
          disabled={!showRightButton}
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={scrollRightHandle}
          aria-label="scroll next"
        >
          <ArrowForwardIos sx={{ color: "#000" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HorizontalScrollCardGrid;

import { useState } from "react";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import SyncIcon from "@mui/icons-material/Sync";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import { currency as cu } from "../../utils/lib";
import DialogComponent from "../../components/dialog";
import EditAccountForm from "../../components/editAccountForm";
import {
  hideLastCharacters,
  toggleCharactersVisibility,
} from "../../utils/utils";

import { toast } from "react-toastify";
import { postData } from "../../fetch/post";

export const OverviewBudget = (props) => {
  const {
    difference,
    positive = false,
    sx,
    value,
    title,
    currency,
    flag,
    number,
    is_active,
    id,
  } = props;

  const visibility = useSelector((state) => state.user.visibility);

  const [open, setOpen] = useState(false);

  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const sync = async (e) => {
    const res = await postData(`${process.env.REACT_APP_ACCOUNT}/sync/`, {
      id,
    });
    if (res.message === "success") {
      toast("sync successfully");
      window.location.reload();
      return;
    }
    toast(res.message);
  };

  return (
    <>
      <DialogComponent
        open={open}
        handleClose={handleClose}
        message="Edit Account"
        Content={<EditAccountForm id={id} />}
      />
      <Card sx={sx}>
        <CardActions
          sx={{
            justifyContent: "space-between",
            margin: "0px",
            padding: "5px 18px",
          }}
        >
          <IconButton onClick={sync}>
            <SyncIcon />
          </IconButton>

          {is_active ? (
            <IconButton>
              <VerifiedIcon color="success" />
            </IconButton>
          ) : null}
        </CardActions>

        <CardContent sx={{ pt: 0 }} onClick={(e) => handleClose(e)}>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">
                {cu(toggleCharactersVisibility(value, visibility), 2, currency)}
              </Typography>
            </Stack>
            <Avatar
              sx={{
                height: 56,
                width: 56,
              }}
              src={flag}
            ></Avatar>
          </Stack>
          {difference && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Stack alignItems="center" direction="row" spacing={0.5}>
                <AccountBalanceRoundedIcon sx={{ color: "#aaa" }} />

                <Typography color={"text.secondary"} variant="body2">
                  {hideLastCharacters(number, visibility)}
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption">
                {currency}
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
};

import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { Layout } from "../../layouts/dashboard/layout";
import { useGet } from "../../hooks/useGet";

import TransactionDetails from '../../pages-sections/transaction/details';

export default function DetailsPage() {
  const { id } = useParams();

  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_TRANSACTION}/${id}/`
  );

  if (loading) {
    return (
      <Layout>
        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Layout>
    );
  }

  return (
    <Layout>
      <TransactionDetails data={data} />
    </Layout>
  );
}

import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loginSchema from "../yup/LoginSchema";

import { login } from "../store/reducers/userReducer";

import Alert from "../components/alert";

export default function LoginForm() {
  const loginUser = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      submit: null,
    },
    validationSchema: loginSchema,
    onSubmit: async (values, helpers) => {
      setOpen(true);
      await dispatch(login(values));
      setOpenAlert(true);
      setOpen(false);
      // helpers.resetForm();
    },
  });

  useEffect(() => {
    if (loginUser.isLoggedIn) {
      navigate("/dashboard");
      return;
    }
  }, [loginUser.isLoggedIn]);

  const handleLink = () => {
    navigate("/forget_password");
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <div>
            <Stack spacing={3}>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <Alert
                open={openAlert}
                message={loginUser.message}
                color={loginUser.color}
                setOpen={setOpenAlert}
              />
            </Stack>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">Connexion</Typography>
              <Typography color="text.secondary" variant="body2">
                Vous n'avez pas de compte ? &nbsp;
                <Link
                  to="/register"
                  style={{ textDecoration: "none" }}
                  underline="hover"
                  variant="subtitle2"
                >
                  S'inscrire
                </Link>
              </Typography>
            </Stack>

            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  error={!!(formik.touched.username && formik.errors.username)}
                  fullWidth
                  helperText={formik.touched.username && formik.errors.username}
                  label="Adresse e-mail"
                  name="username"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.username}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Mot de passe"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
              </Stack>
              <FormHelperText sx={{ mt: 1 }}>
                Requis.
              </FormHelperText>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Continuer
              </Button>
            </form>
            <Typography
              onClick={handleLink}
              sx={{ mt: 3, cursor: "pointer" }}
              textAlign="end"
              variant="body1"
              color="primary"
            >
              Mot de passe oublié
            </Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}

import React, { useState, useCallback } from "react";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import DropZone from "../../components/DropZone";
import BazaarImage from "../../components/BazaarImage";
import { FlexBox } from "../../components/flex-box";
import { UploadImageBox, StyledClear } from "../../components/styledComponents";
import { toast } from "react-toastify";

import { useGet } from "../../hooks/useGet";
import { postBinary, postData } from "../../fetch/post";

import MapSection from "../companies/mapSection";

export default function Kyc() {
  const [states, loading, error] = useGet(
    `${process.env.REACT_APP_GEO}/departement/`
  );

  const [values, setValues] = useState({
    phone: "",
    state: "",
    country: "HAITI",
  });

  const [listCity, setListCity] = useState([]);
  const [listCity2, setListCity2] = useState([]);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);

  //
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);

  const handleChange = useCallback(async (event) => {
    const name = event.target.name;
    setValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
    if (name === "state") {
      const res = await postData(
        `${process.env.REACT_APP_GEO}/arrondissement/`,
        {
          location: event.target.value,
        }
      );
      if (res.message === "success") {
        setListCity(res.data);
        return;
      } else {
        toast(res.message);
        return;
      }
    }
    if (name === "city") {
      const res = await postData(`${process.env.REACT_APP_GEO}/commune/`, {
        location: event.target.value,
      });
      if (res.message === "success") {
        setListCity2(res.data);
        return;
      } else {
        toast(res.message);
        return;
      }
    }
  }, []);

  if (loading) {
    return <h4>Loading...</h4>;
  }

  // HANDLE UPDATE NEW IMAGE VIA DROP ZONE
  const handleChangeDropZone = (files, setFiles) => {
    files.forEach((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles(files);
  };

  // HANDLE DELETE UPLOAD IMAGE
  const handleFileDelete = (file, setFiles) => () => {
    setFiles((files) => files.filter((item) => item.name !== file.name));
  };

  const handleSubmit = (e) => {
    const formData = new FormData();
    e.preventDefault();
    console.log(values);

    for (const key in values) {
      formData.append(key, values[key]);
    }
    
    formData.append("img_front", files[0]);
    formData.append("img_back", files2[0]);
    formData.append("lat", lat);
    formData.append("lng", lng);

    const res = postBinary(
      `${process.env.REACT_APP_USER}/add_user_address/`,
      formData
    );
    if (res.message === "success") {
      toast("Update KYC SUCCESSFULLY");
      setValues({
        phone: "",
        state: "",
        country: "HAITI",
      });
    }
    toast(res.message);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="Update Kyc" title="KYC" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
              />
            </Grid>

            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="State"
                name="state"
                onChange={handleChange}
                required
                value={values.state}
                SelectProps={{ native: true }}
              >
                {states?.map((option) => (
                  <option key={option.value} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                select
                label="City"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                SelectProps={{ native: true }}
              >
                {listCity?.map((option) => (
                  <option key={option.value} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} md={12}>
              <TextField
                fullWidth
                label="City2"
                name="city2"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.city2}
              >
                {listCity2?.map((option) => (
                  <option key={option.value} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                label="Lat"
                name="lat"
                placeholder="lat"
                onChange={handleChange}
                required
                value={lat}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                label="long"
                name="address"
                placeholder="lng"
                onChange={handleChange}
                required
                value={lng}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MapSection lat={lat} lng={lng} setLat={setLat} setLng={setLng} />
            </Grid>
            <Grid xs={12} md={12}>
              <TextField
                fullWidth
                label="address"
                name="address"
                placeholder="#43, fort-national  Port-au-price Haiti"
                onChange={handleChange}
                required
                value={values.address}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                label="Type piece"
                name="option"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={values.option}
              >
                {["Passport", "Id", "License"].map((option, key) => (
                  <option key={key} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Number Card"
                name="number_card"
                onChange={handleChange}
                required
                value={values.number_card}
              />
            </Grid>

            <Grid xs={12} md={6}>
              <span>Image Front</span>
              <DropZone
                onChange={(files) => handleChangeDropZone(files, setFiles)}
              />

              <FlexBox flexDirection="row" mt={2} flexWrap="wrap" gap={1}>
                {files.map((file, index) => {
                  return (
                    <UploadImageBox key={index}>
                      <BazaarImage src={file.preview} width="100%" />
                      <StyledClear onClick={handleFileDelete(file)} />
                    </UploadImageBox>
                  );
                })}
              </FlexBox>
            </Grid>
            <Grid xs={12} md={6}>
              <span>Image Back</span>
              <DropZone
                onChange={(files) => handleChangeDropZone(files, setFiles2)}
              />

              <FlexBox flexDirection="row" mt={2} flexWrap="wrap" gap={1}>
                {files2.map((file, index) => {
                  return (
                    <UploadImageBox key={index}>
                      <BazaarImage src={file.preview} width="100%" />
                      <StyledClear onClick={handleFileDelete(file)} />
                    </UploadImageBox>
                  );
                })}
              </FlexBox>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained">
            Sent Address & Kyc
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}

import React from "react";
import { Box, Typography, Container } from "@mui/material";

const VideoTuto = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Tutoriel Vidéo
        </Typography>
        <Box mt={4} sx={{ display: "flex", justifyContent: "center" }}>
          <video width="100%" controls>
            <source src="path/to/video.mp4" type="video/mp4" />
            Votre navigateur ne supporte pas la lecture de vidéo.
          </video>
        </Box>
      </Container>
    </Box>
  );
};

export default VideoTuto;

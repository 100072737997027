import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqs = [
  {
    question: "Comment s'inscrire?",
    answer:
      'Suivez le processus d\'inscription en cliquant sur "Commencer Maintenant".',
  },
  {
    question: "Quels sont les frais de service?",
    answer: "Les frais de service varient selon le montant échangé.",
  },
];

const Faq = () => {
  return (
    <Box sx={{ padding: 4, backgroundColor: "#f9f9f9" }}>
    <Container>

      <Typography mb={5} variant="h4" align="center" gutterBottom>
        FAQ
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      </Container>
    </Box>
  );
};

export default Faq;

import React from 'react';
import { Box, Typography, Grid, Avatar, Paper, Container } from '@mui/material';

const testimonials = [
  {
    name: 'Jean Dupont',
    feedback: 'Le SIC m\'a permis de changer mes devises en toute sécurité et simplicité.',
    image: 'https://randomuser.me/api/portraits/men/1.jpg',
  },
  {
    name: 'Marie Claire',
    feedback: 'Une plateforme fiable et rapide pour les échanges de devises.',
    image: 'https://randomuser.me/api/portraits/women/1.jpg',
  },
  {
    name: 'Jean-Pierre Louis',
    feedback: 'J\'ai pu échanger mes gourdes contre des dollars sans aucun souci. SIC offre un service très professionnel et facile à utiliser.',
    image: 'https://randomuser.me/api/portraits/men/36.jpg',
  },
  {
    name: 'Sophia Desir',
    feedback: 'L\'interface est intuitive et les transactions sont rapides. SIC est devenu mon go-to pour les échanges de devises.',
    image: 'https://randomuser.me/api/portraits/women/16.jpg',
  },
];

const Testimonials = () => {
  return (
    <Box sx={{ padding: 4 }}>
    <Container maxWidth="lg">
      
    
      <Typography variant="h4" align="center" gutterBottom>Témoignages</Typography>
      <Grid container mt={4} spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper elevation={3} sx={{ padding: 3, display: 'flex', alignItems: 'center' }}>
              <Avatar src={testimonial.image} sx={{ marginRight: 2 }} />
              <Box>
                <Typography variant="h6">{testimonial.name}</Typography>
                <Typography>{testimonial.feedback}</Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      </Container>
    </Box>
  );
};

export default Testimonials;

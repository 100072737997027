import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { Layout } from "../../layouts/dashboard/layout";
import Sent from "../../pages-sections/transaction/withdraw";
import Verify from "../../pages-sections/transaction/withdraw/verify";
import Complete from "../../pages-sections/transaction/convert/complete";

import Stepper from "../../components/stepper";

import { useGet } from "../../hooks/useGet";
import { postData } from "../../fetch/post";

const steps = [
  "Choisir un compte",
  "Vérifier les informations",
  "Compléter",
];

export default function WithDrawPage() {
  const [account, setAccount] = React.useState(null);
  const [accountRecieve, setAccountRecieve] = useState(null);
  const [amount, setAmount] = React.useState(150);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [otp, setOTP] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [sent, setSent] = React.useState(false);

  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get_all/`
  );

  const [listAccount, loading2, error2] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get_account_system/`
  );

  const sendOTPToAPI = async () => {
    setSent(true);
    const res = await postData(`${process.env.REACT_APP_USER}/verify_otp/`, {
      pin: otp,
    });
    if (res.message === "success") {
      setOpen(!open);
      setSent(false);
      handleSentData();
      return;
    } else {
      toast(res.message);
      setSent(false);
    }
    setSent(false);
  };

  if (loading) {
    return <h1>Chargement...</h1>;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    if (!account) {
      toast("Sélectionner un compte");
      return;
    }
    if (!accountRecieve) {
      toast("Sélectionner un compte de réception");
      return;
    }
    if (account?.currency.code === "USD" && amount < 3) {
      toast("Le montant doit être supérieur ou égal à 3");
      return;
    }
    if (account?.currency.code === "HTG" && amount < 50) {
      toast("Le montant doit être supérieur ou égal à 50");
      return;
    }

    if (account?.currency.code !== accountRecieve?.currency?.code) {
      toast("Retirer avec la même devise");
      return;
    }

    if (!amount) {
      toast("Le montant requis");
      return;
    }


    if (Number(account.amount) < Number(amount)) {
      toast("Fonds insuffisants !");
      return;
    }

    const isLastStep = () => {
      return activeStep === steps.length - 1;
    };

    const allStepsCompleted = () => {
      return Object.keys(completed).length === steps.length;
    };

    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };

    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleSentData = async () => {
    const res = await postData(`${process.env.REACT_APP_ACCOUNT}/withdraw/`, {
      account_sender_id: account.id,
      account_recieve_id: accountRecieve.id,
      amount: amount,
      pin: otp,
    });
    if (res.message === "success") {
      handleComplete();
      return;
    } else {
      toast(res.message);
      return null;
    }
  };

  return (
    <Layout>
      <Box>
        <Grid
          container
          mt={5}
          spacing={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Stepper
            steps={steps}
            activeStep={activeStep}
            completed={completed}
          />
        </Grid>

        <h3 style={{ marginLeft: "3%" }}>Retirer de fonds</h3>
        {activeStep === 0 && data ? (
          <Sent
            data={data}
            listAccount={listAccount}
            amount={amount}
            accountRecieve={accountRecieve}
            setAccountRecieve={setAccountRecieve}
            setAccount={setAccount}
            account={account}
            setAmount={setAmount}
            handleComplete={handleComplete}
          />
        ) : null}
        {activeStep === 1 && data ? (
          <Verify
            amount={amount}
            account={account}
            accountReceive={accountRecieve}
            fee={40.0}
            handleBack={handleBack}
            setOTP={setOTP}
            sent={sent}
            sendOTPToAPI={sendOTPToAPI}
            open={open}
            setOpen={setOpen}
          />
        ) : null}
        {activeStep === 2 && data ? <Complete data={data} /> : null}
      </Box>
    </Layout>
  );
}

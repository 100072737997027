import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { postData } from "../fetch/post";

export default function AddCustomer() {
  const [username, setUsername] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const handleSubmit = async () => {
    if (username || accountNumber) {
      const res = await postData(
        `${process.env.REACT_APP_USER}/add_friend_account/`,
        {
          username,
          accountNumber,
        }
      );

      if (res.message === "success") {
        toast("account add successfully !");
        setAccountNumber("");
        setUsername("");
        return;
      } else {
        toast(res.message);
        return;
      }
    } else {
      toast("username or account number required");
      return;
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <TextField
          fullWidth
          label="Username or Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Grid>
      <Grid item md={12}>
        <TextField
          fullWidth
          label="Account Number"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </Grid>
      <Grid item md={12}>
        <Button
          onClick={handleSubmit}
          fullWidth
          variant="outlined"
          color="primary"
        >
          Send
        </Button>
      </Grid>
    </Grid>
  );
}

import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import { Link } from "react-router-dom";

export default function Map({ community, MyComponent }) {
  console.log(community);
  const handleClick = (e) => {
    const { lat, lng } = e.latlng;
    console.log(lat, lng);
  };
  return (
    <MapContainer
      center={[18.971187, -72.285215]}
      zoom={8}
      scrollWheelZoom={false}
      onClick={handleClick}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {<MyComponent /> ? <MyComponent /> : null}

      {/** 
      <Marker position={[18.54472, -72.30278]}>
        <Popup>
          <Link to={`/lookup/community/node/${""}`}>
            {"dfd"} <br />
          </Link>
        </Popup>
      </Marker>
    */}

      {community
        ? community.map((itemPosition) =>
            itemPosition?.address?.lat ? (
              <Marker
                position={[
                  itemPosition?.address?.lat,
                  itemPosition?.address?.long,
                ]}
              >
                <Popup>
                  <Link to={`/transaction/user/${itemPosition.id}`}>
                    {itemPosition.username} <br />
                  </Link>
                </Popup>
              </Marker>
            ) : null
          )
        : null}
    </MapContainer>
  );
}

import React from "react";
import RegisterForm from "../pages-sections/register";
import { Layout } from "../layouts/auth/layout";

export default function Register() {
  return (
    <Layout>
      <RegisterForm />
    </Layout>
  );
}

import React from "react";
import LoginForm from "../pages-sections/login";
import { Layout } from "../layouts/auth/layout";

export default function Login() {
  return (
    <Layout>
      <LoginForm />
    </Layout>
  );
}

import React from "react";
import { Grid, Card, Divider, IconButton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import { currency } from "../../utils/lib";
import VerticalLinearStepper from "../../components/stepper2";

export default function TransactionDetails({ data }) {
  return (
    <Grid container spacing={0}>
      <Grid item md={12}>
        <Typography variant="h4" color="text.primary">
          Transaction Details
        </Typography>
      </Grid>
      <Grid item md={12}>
        <Card
          sx={{
            padding: { xs: "5%", md: "5%" },
            margin: { xs: "10% 3%", md: "1% 3%" },
          }}
        >
          <Grid container spacing={5}>
            <Grid item md={6}>
              <IconButton>
                <Add
                  fontSize="large"
                  sx={{
                    backgroundColor: "rgba(17, 25, 39, 0.38)",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                />
              </IconButton>
              to{" "}
              <span style={{ fontWeight: "bold" }}>
                your account {data?.taux?.code}
              </span>
            </Grid>
            <Grid item md={6}>
              {data?.debit > 0.0 ? (
                <Typography textAlign="end" variant="body1" color="initial">
                  + {currency(data?.debit, 2, data?.taux?.code)}:
                </Typography>
              ) : (
                <Typography textAlign="end" variant="body1" color="initial">
                  - {currency(data?.credit, 2, data?.taux?.code)}:
                </Typography>
              )}
              {data?.debit > 0.0 ? (
                <Typography
                  textAlign="end"
                  variant="body1"
                  color="text.secondary"
                >
                  {" "}
                  {currency(data?.debit, 2, data?.taux?.code)}{" "}
                </Typography>
              ) : (
                <Typography
                  textAlign="end"
                  variant="body1"
                  color="text.secondary"
                >
                  {" "}
                  {currency(data?.credit, 2, data?.taux?.code)}{" "}
                </Typography>
              )}
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>
            <Grid item md={3}>
              <Typography variant="body1" color="initial">
                Create by{" "}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body1" color="initial">
                {data?.customer}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="body1" color="initial">
                Transaction Number
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Typography textAlign="end" variant="body1" color="initial">
                {data?.invoice}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Divider />
            </Grid>

            {/** status bar */}
            <Grid item my={3} md={6}>
              <VerticalLinearStepper
                status={data?.status}
                done={data?.is_paid}
              />
            </Grid>

            {/** transaction details */}
            <Grid item my={3} md={6}>
              <Typography variant="h6" color="initial">
                Transaction Details
              </Typography>
              <Grid container my={1} spacing={2}>
                <Grid item md={6}>
                  <Typography
                    textAlign="start"
                    variant="body1"
                    color="text.secondary"
                  >
                    Create by{" "}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography
                    textAlign="end"
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {data?.customer}
                  </Typography>
                </Grid>

                <Grid item md={6}>
                  <Typography
                    textAlign="start"
                    variant="body1"
                    color="text.secondary"
                  >
                    You sent{" "}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    textAlign="end"
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {currency(data?.debit, 2, data?.taux?.code)}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    textAlign="start"
                    variant="body1"
                    color="text.secondary"
                  >
                    You Withdraw{" "}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    textAlign="end"
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {currency(data?.credit, 2, data?.taux?.code)}
                  </Typography>
                </Grid>
                
                <Grid
                  sx={{
                    borderTop: "1px dotted grey",
                    borderBottom: "1px dotted grey",
                  }}
                  item
                  my={2}
                  py={2}
                  md={6}
                >
                  <Typography
                    textAlign="start"
                    variant="body1"
                    color="text.secondary"
                  >
                    You recieved
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    borderTop: "1px dotted grey",
                    borderBottom: "1px dotted grey",
                  }}
                  item
                  my={2}
                  py={2}
                  md={6}
                >
                  <Typography
                    textAlign="end"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {currency(data?.debit, 2, data?.taux?.code)}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    borderTop: "1px dotted grey",
                    borderBottom: "1px dotted grey",
                  }}
                  item
                  py={2}
                  md={6}
                >
                  <Typography
                    textAlign="start"
                    variant="body1"
                    color="text.secondary"
                  >
                    You Sent
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    borderTop: "1px dotted grey",
                    borderBottom: "1px dotted grey",
                  }}
                  item
                  md={6}
                >
                  <Typography
                    textAlign="end"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {currency(data?.credit, 2, data?.taux?.code)}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    textAlign="start"
                    variant="body1"
                    color="text.secondary"
                  >
                    Transaction ID
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    textAlign="end"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {data?.invoice}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

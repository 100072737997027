import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import MuiTheme from "./theme/index.jsx";

import store from "./store/index";
import Index from "./router/index.jsx";

import "simplebar-react/dist/simplebar.min.css";

import "react-toastify/dist/ReactToastify.css";

import "./index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <MuiTheme>
        <ToastContainer />
        <Index />
      </MuiTheme>
    </Provider>
  </React.StrictMode>
);

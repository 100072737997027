import React from "react";
import { Grid, TextField, Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function formPayment({
  name,
  amount,
  handleFormPayment,
  payment,
  handleSentPaymentForm,
}) {
  return (
    <Grid container spacing={5}>
      <Grid item md={12}>
        <TextField value={name} disabled fullWidth label="Method Payment" />
      </Grid>
      <Grid item md={6}>
        <TextField
          name="transactionId"
          value={payment?.tansactionId}
          fullWidth
          label="ID transaction"
          onChange={handleFormPayment}
        />
      </Grid>
      <Grid item md={6}>
        <TextField
          disabled
          name="amount"
          value={amount}
          fullWidth
          label="Amount"
        />
      </Grid>
      <Grid item md={12}>
        <Button
          fullWidth
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            onChange={handleFormPayment}
            name="file"
            type="file"
          />
        </Button>
        {payment?.file?.name}
      </Grid>

      <Grid item md={12}>
        <Divider />
      </Grid>
      <Grid item md={12}>
        <Button onClick={handleSentPaymentForm} fullWidth variant="outlined">
          Register
        </Button>
      </Grid>
    </Grid>
  );
}

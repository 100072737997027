import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Unstable_Grid2 as Grid,
  Typography,
} from "@mui/material";

export default function Valided() {
  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader subheader="Kyc validé" title="KYC" />
      <Divider />
      <CardContent>
        <Typography textAlign="center" variant="h4" color="secondary">
          Validé
        </Typography>
      </CardContent>
    </Card>
  );
}

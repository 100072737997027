import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { postData } from "../fetch/post";

export default function formAddAccount() {
  const [currency, setCurrency] = useState("");
  const [type_account, setType_account] = useState("");

  const handleSent = async () => {
    if (currency && type_account) {
      const res = await postData(`${process.env.REACT_APP_ACCOUNT}/`, {
        type_account,
        currency,
      });
      if (res.message === "success") {
        toast(res.message);
        window.location.reload();
        return;
      } else {
        toast(res.message);
        return;
      }
    }
    toast("select currency and type account");
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setType_account(e.target.value)}
          select
          fullWidth
          label="Type Account"
          value={type_account}
        >
          <MenuItem value="">....</MenuItem>
          <MenuItem value="Exchange">Exchange</MenuItem>
          <MenuItem value="Bank">Bank</MenuItem>
          <MenuItem value="Withdraw">Withdraw</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          onChange={(e) => setCurrency(e.target.value)}
          select
          fullWidth
          label="Currency"
          value={currency}
        >
          <MenuItem value="">....</MenuItem>
          <MenuItem value="USD">USD</MenuItem>
          <MenuItem value="HTG">HTG</MenuItem>
        </TextField>
      </Grid>
      <Grid item md={12}>
        <Button onClick={handleSent} fullWidth variant="outlined">
          Create
        </Button>
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect } from "react";
import CurrencyExchangeOutlined from "@mui/icons-material/CurrencyExchangeOutlined";
import {
  Grid,
  Card,
  TextField,
  Typography,
  Button,
  InputAdornment,
  SnackbarContent,
} from "@mui/material";

import AutoCompletion from "../../../components/autoComplete";
import { currency } from "../../../utils/lib";

import { useGet } from "../../../hooks/useGet";

export default function Convert({
  data,
  amount,
  accountReceive,
  setAccountReceive,
  setAmount,
  setAccount,
  account,
  taux,
  setTaux,
  handleComplete,
}) {
  const [data2, loading, error] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get_all/`
  );

  const [dataFilter, setDataFilter] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (taux && amount) {
      setOpen(true);
      return;
    }
    setOpen(false);
  }, [taux, amount]);

  const handleChangeAccount = (value) => {
    setDataFilter([]);
    setAccountReceive(null);
    setAccount(value);

    const temp = data2.filter((item) => {
      return (
        item.type_account.name !== value.type_account.name &&
        item.currency.code !== value.currency.code
      );
    });

    setDataFilter((prev) => [...prev, ...temp]);
  };

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        sx={{ padding: "20%, 10%" }}
      >
        <Typography
          align="center"
          color="text.secondary"
          sx={{
            lineHeight: "32px",
          }}
          variant="p"
        >
          Convertir des devises
        </Typography>

        <Grid container mt={3} spacing={{ md: 3, xs: 1 }}>
          {/** Rate */}
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <TextField
              value={taux}
              onChange={(e) => setTaux(parseFloat(e.target.value.replace(/-/g, '')))}
              label="Taux"
              placeholder="ex: 134"
              inputProps={{ min: 1 }}
              type="number"
              InputProps={{ inputProps: { min: 1, max: 10000 } }}
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>

          {/** sender account */}
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <AutoCompletion
              placeholder="Sélectionner le compte émetteur"
              value={account}
              handleChange={(value) => handleChangeAccount(value)}
              data={data}
              show={true}
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>

          {/** Amount */}
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {account ? account?.currency?.code : ""}
                  </InputAdornment>
                ),
                min: 1,
              }}
              fullWidth
              name="amount"
              label="Montant"
              type="number"
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value.replace(/-/g, '')))}
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>

          {/** account recieve */}
          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <AutoCompletion
              placeholder="Sélectionner le compte receveur"
              value={accountReceive}
              handleChange={(value) => setAccountReceive(value)}
              data={dataFilter}
              show={true}
            />
          </Grid>
          <Grid item md={3} xs={12}></Grid>

          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            {open && account ? (
              <SnackbarContent
                color="secondary"
                message={`Vous recevrez ${
                  account.currency?.code === "USD"
                    ? currency(amount * taux, 2, "HTG")
                    : currency(amount / taux, 2, "USD")
                }`}
              />
            ) : null}
          </Grid>

          <Grid item md={3} xs={12}></Grid>

          <Grid item md={3} xs={12}></Grid>
          <Grid item md={6} xs={12}>
            <Button
              onClick={handleComplete}
              startIcon={<CurrencyExchangeOutlined />}
              fullWidth
              variant="outlined"
            >
              Convertir de devises
            </Button>
          </Grid>
          <Grid item md={3} xs={12}></Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

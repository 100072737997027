import * as Yup from "yup";

/**
 * The password must match:
 *
 * At least 8 - 16 characters,
 * must contain at least 1 uppercase letter,
 * must contain at least 1 lowercase letter,
 * and 1 number
 * Can contain any of these special characters: $ % # * & - .
 */
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/;

const loginSchema = Yup.object({
    username: Yup.string().required("Requis"),
    password: Yup.string()
        .matches(passwordRules, { message: "Veuillez créer un mot de passe plus fort !" })
        .min(8, "Doit contenir au moins 8 caractères").max(16, "Doit contenir au plus 16 caractères")
        .required("Requis"),
});

export default loginSchema;

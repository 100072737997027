import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, Container, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Remplacez ce lien par l'URL de votre propre image
const imageUrl = 'https://img.freepik.com/vecteurs-premium/file-attente-bancaire-personnes-debout-dans-rangee-pour-clients-employes-banque-verifiant-paiements-personnages-dessins-animes-vectoriels-attendant-ligne_176516-3000.jpg';

const steps = [
  'Inscrivez-vous et créez un compte.',
  'Recherchez des partenaires d\'échange selon vos critères.',
  'Effectuez des transactions sécurisées via notre plateforme.',
  'Suivez vos transactions dans l\'historique.',
];

const HowItWorks = () => {
  return (
    <Box sx={{ padding: 4, backgroundColor: '#f9f9f9' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Comment ça Marche
        </Typography>
        
        {/* Disposition flex avec l'image et le texte côte à côte */}
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 4 , mt: 5}}>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <img src={imageUrl} alt="File d'attente devant une banque" style={{ maxWidth: '100%', height: 'auto', borderRadius: 8 }} />
          </Box>
          <Box sx={{ flex: 1, paddingLeft: 2 }}>
            <Typography variant="h6" gutterBottom>
              Voici comment notre plateforme simplifie les échanges de devises :
            </Typography>
            <List>
              {steps.map((step, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={step} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HowItWorks;

import { Layout } from "../layouts/dashboard/layout";
import Index from ".";

export default function App() {
  

  return (
    
      <Index />
   
  );
}

import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import PrimaryInput from "@mui/material/TextField";
import Box from "@mui/material/Box";

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function AutoCompletion({
  placeholder,
  value,
  handleChange,
  data,
  hide = false,
  show = false,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active && data) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <Autocomplete
      id="asynchronous-demo"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        handleChange(value);
      }}
      value={value}
      isOptionEqualToValue={(option, value) => option.number === value.number}
      getOptionLabel={(option) => option.number}
      renderOption={(props, option) => (
        <Box {...props} sx={{ display: "flex", alignItems: "center" }}>
          <div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginRight: "16px",
              }}
            >
              {hide ? "" : option.currency?.code}
            </span>
            <span style={{ color: "gray", fontSize: "24px" }}>
              {show
                ? `${option.number} - ${option.amount} ${option.currency.code}`
                : `${option.number}`}
            </span>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {hide ? (
              ""
            ) : (
              <img
                loading="lazy"
                width="50"
                srcSet={option.currency?.flag}
                src={option.currency?.flag}
                alt="flag country"
              />
            )}
          </div>
        </Box>
      )}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <PrimaryInput
          {...params}
          label={placeholder}
          placeholder={placeholder}
          size="medium"
          color="info"
          sx={{ marginBottom: open ? "18px" : "0" }} // Add margin when dropdown is open
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
import React from 'react';
import { Box, Typography, Grid, Paper, Container } from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SecurityIcon from '@mui/icons-material/Security';
import SearchIcon from '@mui/icons-material/Search';

const features = [
  { icon: <CurrencyExchangeIcon />, title: 'Transactions Sécurisées', description: 'Facilitez les transactions de change entre utilisateurs.' },
  { icon: <SecurityIcon />, title: 'Sécurité Maximale', description: 'Toutes les transactions sont sécurisées et vérifiées.' },
  { icon: <SearchIcon />, title: 'Recherche Avancée', description: 'Trouvez des partenaires d\'échange selon vos critères.' },
];

const Features = () => {
  return (
    <Box sx={{ padding: 4 }}>
     <Container maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom>Fonctionnalités</Typography>
      <Grid container mt={4} spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
              {feature.icon}
              <Typography variant="h6" gutterBottom>{feature.title}</Typography>
              <Typography>{feature.description}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      </Container>
    </Box>
  );
};

export default Features;

import React, { useState } from "react";
import { Grid, Card, Typography, Button } from "@mui/material";

import Dialog from "../../../components/dialog";
import OTPForm from "../../account/user/verify";
import { currency } from "../../../utils/lib";
import { usePost } from "../../../hooks/usePost";

export default function VerifyConvert({
  account,
  accountReceive,
  amount,
  fee,
  handleBack,
  setOTP,
  sendOTPToAPI,
  sent,
  open,
  setOpen,
}) {
  const message = "Vérifiez avec OTP";
  const handleClose = () => {
    setOpen(!open);
  };

  const [fees, error] = usePost(
    `${process.env.REACT_APP_ACCOUNT}/fees/`,
    {
      fees: account.currency.code,
    },
    [account]
  );

  /**
  const [fees, error] = usePost(
    `${process.env.REACT_APP_ACCOUNT}/rate/`,
    {
      rates: account?.currency?.code === "USD" ? "HTG" : "USD",
    },
    [account]
  );

  */

  if (error) {
    return <h4>Erreur lors de la récupération des frais</h4>;
  }

  console.log(fees);

  return (
    <Card
      sx={{
        padding: { xs: "5%", md: "5%" },
        margin: { xs: "10% 3%", md: "1% 3%" },
      }}
    >
      <Dialog
        Content={
          <OTPForm
            open={sent}
            sendOTPToAPI={sendOTPToAPI}
            setOTPPass={setOTP}
          />
        }
        open={open}
        handleClose={handleClose}
        message={message}
      />

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        sx={{ padding: "20px", backgroundColor: "#f4f4f4" }}
      >
        <Typography
          align="center"
          color="text.secondary"
          sx={{
            lineHeight: "32px",
          }}
          variant="p"
        >
          Veuillez vérifier votre transaction avant de la confirmer
        </Typography>

        {/* Account Number Sender */}
        <Grid
          container
          mt={5}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" fontWeight={700}>
              Compte expéditeur
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" fontWeight={700}>
              {account.number} - {account.amount} {account.currency?.code}
            </Typography>
          </Grid>
        </Grid>

        {/* Account Number Recieve */}
        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" fontWeight={700}>
              Compte receveur
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" fontWeight={700}>
              {accountReceive.number} - ***** {accountReceive.currency?.code}
            </Typography>
          </Grid>
        </Grid>

        {/* Amount */}
        <Grid
          container
          mt={0}
          sx={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="start" fontWeight={700}>
              Montant
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Typography variant="body1" textAlign="end" fontWeight={700}>
              {currency(amount, 2, account.currency?.code)}
            </Typography>
          </Grid>
        </Grid>

        {/* Exchange rate */}
        {accountReceive.currency?.code === account.currency?.code ? null : (
          <Grid
            container
            mt={0}
            sx={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Taux de change
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                1 {account.currency?.code} =&gt; {accountReceive.currency.code}
                {fees?.currency?.taux.replace(".", ",")}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* Fee */}

        {/* Total */}
        {accountReceive.currency?.code === account.currency?.code ? (
          <Grid
            container
            sx={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Montant Total
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {currency(parseFloat(amount), 2, accountReceive.currency?.code)}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              backgroundColor: "#fff",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="start" fontWeight={700}>
                Montant converti
              </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <Typography variant="body1" textAlign="end" fontWeight={700}>
                {currency(
                  parseFloat(amount * fees?.currency?.taux),

                  2,
                  accountReceive.currency?.code
                )}
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* Verify Button */}
        <Grid item mt={5} md={12} xs={12}>
          <Button
            onClick={handleClose}
            sx={{ float: "right", marginLeft: "16px" }}
            variant="outlined"
          >
            Vérifier
          </Button>
          <Button
            onClick={() => handleBack()}
            sx={{ float: "right" }}
            variant="outlined"
          >
            Retour
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

import { useState, useEffect } from "react";

const useSessionStorage = (name) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    if (window) {
      // set props data to session storage or local storage
      if (
        sessionStorage.getItem(name) === undefined ||
        sessionStorage.getItem(name) === null
      ) {
        window.location.href = "/";
        return;
      }
      setValue(JSON.parse(sessionStorage.getItem(name)));
    }
  }, []);

  return value;
};

export default useSessionStorage;

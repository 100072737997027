import { subDays, subHours } from "date-fns";
import { Box, Button, Container, Unstable_Grid2 as Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { OverviewBudget } from "../pages-sections/overview/overview-budget";
import { OverviewLatestOrders } from "../pages-sections/overview/overview-latest-orders";
import { OverviewSales } from "../pages-sections/overview/overview-sales";
import { OverviewTraffic } from "../pages-sections/overview/overview-traffic";
import HorizontalScrollCardGrid from "../components/horizontalScrollCardGrid";
import Analytics from "../components/analyse";
import { Add, ArrowDownward, ArrowUpward } from "@mui/icons-material";

import { Layout } from "../layouts/dashboard/layout";
import { useGet } from "../hooks/useGet";
import { usePost } from "../hooks/usePost";

const now = new Date().toISOString().split("T")[0];

// Calculate the date one year ago
const oneYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
const oneYearAgoString = oneYearAgo.toISOString().split("T")[0];

console.log("One year ago: " + oneYearAgoString);

const Page = () => {


   const navigate = useNavigate();
   const user = useSelector((state) => state.user.user);

   useEffect(() => {
     if (!user.is_email_check)
       navigate("/user/verify");
   }, [user, navigate]);

  const [data, loading, error] = useGet(
    `${process.env.REACT_APP_TRANSACTION}/user/`
  );

  const [account, load, error3] = useGet(
    `${process.env.REACT_APP_ACCOUNT}/get_all/`
  );

  const [analyse, error4] = usePost(
    `${process.env.REACT_APP_ANALYSE}/bank_reports/`,
    {
      startDate: oneYearAgo,
      endDate: now,
    },
    []
  );

  const [traffic, load2, error5] = useGet(
    `${process.env.REACT_APP_ANALYSE}/split/`
  );

  if (error) {
    return <h3>Erreur de récupération des transactions</h3>;
  }

  return (
    <Layout >
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Button
              LinkComponent={Link}
              to="/transaction/add"
              variant="outlined"
              sx={{
                marginRight: "10px",
                padding: { md: "5px, 32px", xs: "5px 13px" },
              }}
              startIcon={<Add />}
            >
              Ajouter de l'argent
            </Button>

            <Button
              LinkComponent={Link}
              to="/transaction/send"
              variant="outlined"
              sx={{
                marginRight: "10px",
                padding: { md: "5px, 32px", xs: "5px 13px" },
              }}
              startIcon={<ArrowUpward />}
            >
              Convertir
            </Button>
          </Grid>

          <HorizontalScrollCardGrid
            data={account}
            loading={load}
            CardItem={OverviewBudget}
          />

          <Grid xs={12} lg={8}>
            <Analytics details={analyse} />
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            {traffic ? (
              <OverviewTraffic
                chartSeries={[traffic.debit, traffic.credit]}
                labels={["Débit", "Crédit"]}
                sx={{ height: "100%" }}
              />
            ) : (
              "Chargement"
            )}
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <OverviewLatestOrders
              orders={data ? data.data : []}
              sx={{ height: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
    </Layout>
  );
};

export default Page;

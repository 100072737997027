import PropTypes from "prop-types";
import CurrencyExchangeOutlined from "@mui/icons-material/CurrencyExchangeOutlined";
import ClockIcon from "@heroicons/react/24/solid/ClockIcon";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 64,
      height: 64,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export const CompanyCard = (props) => {
  const { company } = props;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <CardContent>
        {/** 
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: 3,
          }}
        >
          <Avatar
            {...stringAvatar(
              `T H`
            )}
          />
        </Box>
        
        <Typography align="center" gutterBottom variant="h5">
          {company.username}
        </Typography>
        <Typography align="center" variant="body1">
          {company.description}
        </Typography>
        */}
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />

      {company?.currencys.map((value, key) => (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          key={key}
          spacing={2}
          sx={{ p: 2 }}
        >
          <Stack alignItems="center" direction="row" spacing={1}>
            <SvgIcon color="action" fontSize="small">
              <ClockIcon />
            </SvgIcon>
            <Typography color="text.secondary" display="inline" variant="body2">
              Updated{" "}
              {String(value.update_at).substring(5, 19).replace("T", " ")}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={1}>
            <SvgIcon color="action" fontSize="small">
              <CurrencyExchangeOutlined />
            </SvgIcon>
            <Typography
              textAlign="start"
              color="text.secondary"
              display="inline"
              variant="p"
            >
               {value.code === "USD" ? "Sale" : "Purchase"}{" "}
              <span>
                {" "}
                <ArrowForward
                  sx={{ paddingTop: "7px", color: "red" }}
                  fontSize="small"
                />{" "}
              </span>{" "}
              {value.code === "HTG"
                ? parseFloat(value.taux).toFixed(2)
                : value.taux}
            </Typography>
          </Stack>
        </Stack>
      ))}
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        my={3}
      >
        <Button
          LinkComponent={Link}
          to={`/transaction/user/${company.id}`}
          variant="outlined"
        >
          Buy devise
        </Button>
      </Grid>
    </Card>
  );
};

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired,
};

import React from 'react'
import { Layout } from "../layouts/dashboard/layout";
import Index from "../pages-sections/account";

export default function Account() {
  return (
    <Layout>
        <Index />
    </Layout>
  )
}

import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  const navigate = useNavigate();

  const transactionId = new URLSearchParams(location.search).get(
    "transactionId"
  );

  console.log(transactionId);

  useEffect(() => {
    // Appel à l'API pour récupérer les détails de la transaction
    // Extract transactionId from URL query parameters

    const fetchInvoiceDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PAYMENT}/verify_transaction/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ transactionId }), // Pass transactionId in the body as JSON
          }
        );

        if (!response.ok) {
          throw new Error("Failed to load invoice details.");
        }

        const data = await response.json();
        setInvoiceDetails(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load invoice details.");
        setLoading(false);
      }
    };

    fetchInvoiceDetails();
  }, [transactionId]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_PAYMENT}/auth/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }), // Send email and password as JSON
      });

      if (!response.ok) {
        throw new Error("Failed to login. Please check your credentials.");
      }

      const data = await response.json();
      // Store the token or handle the login response as needed
      console.log("Login successful:", data);
      setLoading(false);

      navigate(
        `/payment/auth/verify?transactionId=${transactionId}&token=${data.access}`
      );
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card sx={{ padding: 4, width: 400 }}>
        <CardContent>
          <Box display="flex" justifyContent="center" marginBottom={2}>
            <img
              src={invoiceDetails.companyLogoUrl}
              alt={`${invoiceDetails.companyName} logo`}
              style={{ height: "60px" }}
            />
          </Box>
          <Typography variant="h6" align="center">
            {invoiceDetails.companyName}
          </Typography>
          <Typography variant="body1" align="center" marginBottom={2}>
            Montant à payer : {invoiceDetails.amount} {invoiceDetails.currency}
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Mot de passe"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Connexion
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AuthPage;
